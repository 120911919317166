/** THIS IS NOT THE FILE YOU ARE LOOKING FOR */
/*  This file exists for legacy purposes only */
/*  There is now an embed-direqt-ai project which */
/*  contains our entire embed script along with our custom elements */
/*  if you're looking in here, you probably meant to look in there */

const direqt_local = import.meta.env.VITE_DIREQT_LOCAL === 'true';

const initBetaEmbed = () => {
    console.log('[DIREQT] Legacy setup detected. Loading current embed.js...');

    const script = document.createElement('script');
    script.setAttribute('type', 'module');
    script.toggleAttribute('async');
    const embedUrl = direqt_local
        ? `http://localhost:5173/src/embed.ts?inject=false`
        : `https://embed.direqt.ai/embed.js?inject=false`;

    const ins = document.querySelector('ins.direqt-embed') as HTMLElement;
    const botId = ins?.dataset.botId || null;
    if (botId) script.setAttribute('src', `${embedUrl}&id=${botId}`);
    else {
        console.error(
            '[DIREQT] No bot ID found. Please add bot ID to the ins.direqt-embed element or update embed script. https://docs.direqt.ai/implementing-webchat/'
        );
        return false;
    }

    document.head.appendChild(script);
    console.log('[DIREQT] New embed.js loaded. Enjoy Direqt!');

    return true;
};

if (document.readyState === 'loading') {
    const chatUrl = direqt_local
        ? 'http://localhost:8080'
        : 'https://chat.direqt.ai';
    fetch(chatUrl, {
        method: 'POST',
        body: JSON.stringify({ event: 'direqt-legacy-embed-loaded' }),
    });
    window.addEventListener(
        'DOMContentLoaded',
        async () => {
            initBetaEmbed();
        },
        { once: true }
    );
} else {
    initBetaEmbed();
}
